import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flag from './flag';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  width: 100%;
  max-width: 525px;
  margin: 0 auto;
  flex-basis: 65px;

  img {
    display: block;
    margin: 0 auto;
    width: 65px;
    margin: 0px;
  }
`;

const FlagList = ({ flags }) => (
  <Style>
    {flags.map((flag) => <Flag key={flag} name={flag} />)}
  </Style>
);

FlagList.propTypes = {
  flags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FlagList;
